<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status">{{ group }}</span>
          </div>
          <b-avatar
            size="40"
            class="badge-minimal icon-color primary"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="() => this.$router.push('/perfil')"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>
            Sair
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
    BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';

import { useSessionStorage } from '@vueuse/core';
import taxId from '@/filters/taxId';
import AuthService from '@/services/osiris/AuthService';

import { Crypto } from '@/libs/crypto';

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        infoNavBar() {
            const pathSplitted = (this.$router.history.current.path.split('/'));
            const isCreditPlatform = pathSplitted.includes('plataforma-credito') && pathSplitted.length > 2;

            return {
                name: isCreditPlatform ? this.$store.getters['creditPlatform/merchant'].legal_tax_name : 'Gustavo',
                complement: isCreditPlatform ? this.$store.getters['creditPlatform/merchant'].asset_holder : 'TI',
            };
        },

        name() {
            const { name } = JSON.parse(Crypto.decrypt(sessionStorage.getItem('r_')));

            return name;
        },

        group() {
            const { group: { company_name } } = JSON.parse(Crypto.decrypt(sessionStorage.getItem('r_')));

            return company_name;
        },
    },

    methods: {
        logout() {
            AuthService.logout();
            sessionStorage.clear();
            this.$router.push('/login');
        },
    },
};
</script>
