import { Crypto } from '@/libs/crypto';
import UserService from '@/services/osiris/UserService';

const menu = [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
        admin: false,
    },
    {
        title: 'Estabelecimentos e Contratos',
        route: 'credit-platform-home',
        icon: 'FileIcon',
        admin: false,
    },
    // {
    //     title: 'Criar Usuário',
    //     route: 'create-user',
    //     icon: 'UserPlusIcon',
    //     admin: true,
    // },
    // {
    //     title: 'Editar Usuário',
    //     route: 'edit-user',
    //     icon: 'UserPlusIcon',
    //     admin: true,
    // },
    {
        title: 'Extrato',
        route: 'extract',
        icon: 'TrendingUpIcon',
        admin: false,
    },
    {
        title: 'Taxas',
        route: 'rates',
        icon: 'DollarSignIcon',
        admin: false,
    },
    {
        title: 'Horário de Funcionamento',
        route: 'registers',
        icon: 'ClockIcon',
        admin: false,
    },
    {
        title: 'Taxas Padrão',
        route: 'rates-default',
        icon: 'DollarSignIcon',
        admin: true,
        fullAccess: true,
    },
];

export const menuItems = async () => {
    const idDecrypted = Crypto.decrypt(sessionStorage.getItem('id'));

    const {
        data,
    } = await UserService.show(idDecrypted);

    const fullAccess = false;

    const { permissions } = data;

    if (fullAccess) {
        return menu;
    }

    const isUserAdmin = permissions.includes(1);

    if (isUserAdmin) {
        return menu.filter(item => !item.fullAccess);
    }

    return menu.filter(item => !item.admin && !item.fullAccess);
};
